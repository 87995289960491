import pic1 from '../assets/img/media/media_001.png'
import pic2 from '../assets/img/media/media_002.png'
import pic3 from '../assets/img/media/media_003.png'
import pic4 from '../assets/img/media/media_004.png'

const publicationsArray = [
    {
        pic: pic1,
        title: 'budni.robota.ua',
        text: 'How to create an individual development plan for employees?',
        href: 'https://budni.robota.ua/hr/yak-sklasti-individualniy-plan-rozvitku-dlya-spivrobitnikiv-2',
        id: 1
    },
    {
        pic: pic2,
        title: 'Б24.com.ua',
        text: 'How much does it cost to recruit a new employee, and how can we reduce hiring costs?',
        href: 'https://www.buh24.com.ua/skilky-koshtuye-poshuk-novogo-spivrobitnyka-i-yak-znyzyty-vytraty-na-najm/',
        id: 2
    },
    {
        pic: pic3,
        title: 'management.com.ua',
        text: 'How to develop individual employee development plans?',
        href: 'https://www.management.com.ua/hrm/hrm365.html',
        id:3
    },
    {
        pic: pic4,
        title: 'staff-capital.com',
        text: 'How to develop individual employee development plans?',
        href: 'https://staff-capital.com/%D1%8F%D0%BA-%D1%81%D0%BA%D0%BB%D0%B0%D1%81%D1%82%D0%B8-%D1%96%D0%BD%D0%B4%D0%B8%D0%B2%D1%96%D0%B4%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D1%96-%D0%BF%D0%BB%D0%B0%D0%BD%D0%B8-%D1%80%D0%BE%D0%B7%D0%B2%D0%B8/',
        id: 4
    },
]

export default publicationsArray;
