import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import benefits from '../../assets/img/svg/benefits.svg';

const BenefitsBS = () => {
  return (
    <section id="benefits" className="bb bt">
        <div className="benefits-image">
          <h3 className="h3">our advantages</h3>
          <LazyLoadImage src={benefits} height={614} width={510} />
        </div>
        <div className="benefits-text">
          <div className="benefits-inner">
            <div className="block bb">
              <h3 className="h3">effective PR strategies</h3>
              <p className="body-text5">
              Development and implementation of strategies for effective lead generation and increasing the visibility of your business on LinkedIn.
              </p>
            </div>
            <div className="block bb">
              <h3 className="h3">Personalized approach</h3>
              <p className="body-text5">
              Personalized analysis and optimization of your business profile on LinkedIn to attract a larger number of potential clients.
              </p>
            </div>
            <div className="block bb">
              <h3 className="h3">Automated campaign management</h3>
              <p className="body-text5">
              Using modern tools to automate lead generation campaigns.
              </p>
            </div>
            <div className="block">
              <h3 className="h3">Networking opportunities</h3>
              <p className="body-text5">Access to a wide network of potential clients and partners in the B2B sector with the ESUPPORT team.
              </p>
            </div>
          </div>
        </div>
      </section>
  )
}

export default BenefitsBS
