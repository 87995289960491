import React from 'react';

const Promo2PR = () => {
  return (
    <section id="promo2">
      <div className="promo2-inner">
        <p className="body-text4">
          We invite you to participate in <strong>our courses</strong>, which include paid internships and the opportunity <strong>for employment</strong> upon successful completion of the training.
        </p>
        <a
          target="blank"
          href="/"
          className="text-dec-n"
        >
          <button className="pr-btn">HOW MUCH CAN I EARN</button>
        </a>
      </div>
    </section>
  );
};

export default Promo2PR;
