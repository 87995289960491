import React from 'react';

const Promo3BS = () => {
  return (
    <section id="promo3" className="bb">
      <div className="promo3-inner">
        <div className="flex aic">
          <div className="item"></div>
          <h4 className="h4">research and analytics</h4>
        </div>
        <p className="body-text4">Turn data into action: discover new opportunities for your business with our in-depth analytical reports and recommendations.</p>
        <div>
          <a
            className="text-dec-n"
            target="blank"
            href="https://form.typeform.com/to/GMST2l9O#book_call=xxxxx&start_now=xxxxx&free_audit=xxxxx&get_icp=true"
          >
            <button className="pr-btn">
              RECEIVE ICP FOR FREE
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Promo3BS;
