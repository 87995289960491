import React from 'react';

const Promo2BS = () => {
  return (
    <section id="promo2">
      <div className="promo2-inner">
      <p className="body-text4">
        RECEIVE A <strong>FREE AUDIT</strong> OF YOUR BUSINESS FROM <strong>ESUPPORT</strong> EXPERTS AND DISCOVER NEW HORIZONS FOR SCALING YOUR BUSINESS.
      </p>
        <a
          target='blank'
          href='https://form.typeform.com/to/GMST2l9O#book_call=xxxxx&start_now=xxxxx&free_audit=true&get_icp=xxxxx'
          className="text-dec-n">
            <button className="pr-btn">RECEIVE A FREE AUDIT</button>
        </a>
      </div>
    </section>
  );
};

export default Promo2BS;
