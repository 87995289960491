import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import business from '../../assets/img/svg/business.svg';

const BusinessBS = () => {
  return (
    <section id="business" className="bt bb">
      <div className="business-image br">
        <LazyLoadImage src={business} width={408} height={326} />
      </div>
      <div className="pl-6">
        <div className="flex aic mb-8">
          <div className="item"></div>
          <h3 className="h3">for business</h3>
        </div>
        <p className="body-text2">Expand your commercial horizons and establish strong partnership relationships with key players in your industry with the help of our LinkedIn lead generation services.</p>
      </div>
    </section>
  );
};

export default BusinessBS;
