import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import benefits from '../../assets/img/svg/benefits.svg';

const BenefitsPR = () => {
  return (
    <section id="benefits" className="bb bt">
        <div className="benefits-image">
          <h3 className="h3">our advantages</h3>
          <LazyLoadImage src={benefits} height={614} width={510} />
        </div>
        <div className="benefits-text">
          <div className="benefits-inner">
            <div className="block bb">
              <h3 className="h3">Lead generation strategies</h3>
              <p className="body-text5">We provide knowledge and practical skills for developing and implementing effective lead generation strategies on LinkedIn.
              </p>
            </div>
            <div className="block bb">
              <h3 className="h3">Individual approach and support</h3>
              <p className="body-text5">Receive individualized attention and support in professional development from our team of industry leaders who are committed to your success.
              </p>
            </div>
            <div className="block bb">
              <h3 className="h3">Using automated tools for effective management of lead generation campaigns.</h3>
              <p className="body-text5">Enhance your skills in automating PR campaigns using advanced technological solutions and optimize client communication processes.
              </p>
            </div>
            <div className="block">
              <h3 className="h3">Networking opportunities</h3>
              <p className="body-text5">Expand your professional horizon by establishing connections with leading industry experts and gaining access to exclusive networking events in the PR field through our LinkedIn community.
              </p>
            </div>
          </div>
        </div>
      </section>
  )
}

export default BenefitsPR
