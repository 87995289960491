import React, { useCallback } from 'react';
import IconButtonNext from '../utils/IconButtonNext';
import IconButtonPrevious from '../utils/IconButtonPrevious';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import publications from '../../assets/img/svg/publications.svg';
import publicationsArray from '../../data/publications';
import useEmblaCarousel from 'embla-carousel-react';

const Publications = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({loop:true});

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  return (
    <section id="publications">
      <div className="publications-text">
        <h3 className="h3 section-title">publications</h3>

        <div className="embla" ref={emblaRef}>
          <div className="embla__container">
            {publicationsArray.map((publication) => (
              <div className="embla__slide" key={publication.id}>
                <div className="flex aic publication-title">
                  <img src={publication.pic} alt={publication.title} width={160} height={160} />
                  <h3 className="h3" style={{paddingLeft: 20}}>{publication.title}</h3>
                </div>
                <p className="body-text5">{publication.text}</p>
                <div className="flex aic jcsb">
                  <div className="flex gap-48">
                    <IconButtonPrevious onClick={scrollPrev}/>
                    <IconButtonNext onClick={scrollNext}/>
                  </div>
                  <div>
                    <a href={publication.href} className='text-dec-n' target='blank'>
                      <button className="sec-btn">MORE</button>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='image'>
        <LazyLoadImage src={publications} width={486} height={450} />
      </div>
    </section>
  );
};

export default Publications;
