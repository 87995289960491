import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { BsFillTelephoneFill } from 'react-icons/bs';
import intro from '../../assets/img/svg/intro.svg';
import { selectNavigationLink } from '../../redux/slices/navigationSlice';

const Intro = () => {
  const navValue = useSelector(selectNavigationLink);
  return (
    <section id="intro" className="bb">
      <div className="intro-text">
        <h1 className="h1">ESUPPORT</h1>
        <p className="body-text6">
          The company ESUPPORT specializes in B2B lead generation through LinkedIn, offering services that enable your business to establish and strengthen business relationships with potential clients and partners. Our services will assist your business in setting up and fortifying business connections with potential clients and partners, utilizing powerful communication and marketing tools to achieve your strategic business objectives.
        </p>
        <div className="buttons flex jcsb">
          <a
            className="text-dec-n"
            target="blank"
            href="https://form.typeform.com/to/GMST2l9O#book_call=true&start_now=xxxxx&free_audit=xxxxx&get_icp=xxxxx"
          >
            <button className="sec-btn">
              REQUEST A CALL
              <BsFillTelephoneFill />
            </button>
          </a>
          <a
            target="blank"
            href={
              navValue === 'business'
                ? 'https://form.typeform.com/to/GMST2l9O#book_call=xxxxx&start_now=true&free_audit=xxxxx&get_icp=xxxxx'
                : 'https://form.typeform.com/to/GMST2l9O#book_call=xxxxx&start_now=true&free_audit=xxxxx&get_icp=xxxxx'
            }
            className="text-dec-n"
          >
            <button className="pr-btn">Start Now</button>
          </a>
        </div>
      </div>
      <div className="intro-image">
        <LazyLoadImage src={intro} width={473.5} />
      </div>
    </section>
  );
};

export default Intro;
