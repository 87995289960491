import React from 'react'

const Promo1 = () => {
  return (
    <section id="promo1">
        <h2 className="h2 textr">BOOST THE EFFECTIVENESS OF</h2>
        <h2 className="h2 pl-25 mb-4">YOUR B2B LEAD GENERATION!</h2>
        <p className="body-text1">Professional communication strategies online.</p>
        <h3 className="h3">LinkedIn</h3>
      </section>
  )
}

export default Promo1
