import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import business from '../../assets/img/svg/business.svg';

const BusinessPR = () => {
    return (
    <section id="business" className="bt bb sec">
      <div className="business-image br">
        <LazyLoadImage src={business} width={408} height={326} />
      </div>
      <div className="pl-6 sec">
        <div className="flex aic mb-8">
          <div className="item"></div>
          <h3 className="h3">To lead generators</h3>
        </div>
        <p className="body-text2">ESUPPORT offers an innovative platform for professionals in the lead generation field who wish to deepen their skills in the B2B segment and utilize effective LinkedIn tools to achieve professional success. Our resources and trainings focus on the practical application of strategies and methodologies that will help you efficiently attract potential clients and optimize your sales.</p>
      </div>
    </section>
  );
};

export default BusinessPR;
