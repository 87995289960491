import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { BsCardChecklist } from 'react-icons/bs';
import intro from '../../assets/img/svg/hiring.svg';
import { selectNavigationLink } from '../../redux/slices/navigationSlice';

const IntroPR = () => {
  const navValue = useSelector(selectNavigationLink);
  return (
    <section id="intro" className="bb">
      <div className="intro-text">
        <h1 className="h1">ESUPPORT</h1>
        <p className="body-text6">
          ESUPPORT offers quick courses on lead generation in LinkedIn, which help participants without experience develop skills and find new opportunities for career growth in the B2B segment.
        </p>
        <p></p>
        <p></p>
        <p></p>
        <div className="buttons flex jcsb">
          <a
            className="text-dec-n"
            target="blank"
            href="https://docs.google.com/document/d/14KFm4tXRpGzlx0ppgwTToDri2IWDJwvgW3o9FZH6qXE/edit?usp=sharing"
          >
            <button className="sec-btn">
              OUR PROGRAM
              <BsCardChecklist />
            </button>
          </a>
          <a
            target="blank"
            href={
              navValue === 'business'
                ? 'https://form.typeform.com/to/GMST2l9O#book_call=xxxxx&start_now=true&free_audit=xxxxx&get_icp=xxxxx'
                : 'https://t.me/+IajyUr7r9zcwZjli'
            }
            className="text-dec-n"
          >
            <button className="pr-btn">Start Now</button>
          </a>
        </div>
      </div>
      <div className="intro-image">
        <LazyLoadImage src={intro} width={473.5} />
      </div>
    </section>
  );
};

export default IntroPR;
